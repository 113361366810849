<template>
  <div class="notice">
    <div class="notice-body">
      <div class="goods-card" v-for="(item, index) in orderList" :key="index">
        <div class="card-title" @click="clickTitle(item)">
          <div class="address-text">
            {{ item.province }} {{ item.city }} {{ item.town }}
            {{ item.address }}
          </div>
          <div class="user-and-mobile">
            <div class="real_name">{{ item.real_name }}</div>
            <div class="mobile">{{ item.mobile }}</div>
          </div>
        </div>
        <div
          class="goods-list"
          v-for="(goods, goodsIndex) in item.goods"
          :key="goodsIndex"
        >
          <div class="goods-cover">
            <img :src="goods.cover" />
          </div>
          <div class="goods-info">
            <div class="goods-title">{{ goods.title }}</div>
            <div class="specs-text-and-number">
              <div class="spec-text">
                {{ goods.spec_text ? goods.spec_text : "默认规格" }}
              </div>
              <div class="goods-number">× {{ goods.number }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <nut-popup
      v-model="show"
      round
      position="bottom"
      :style="{ height: '40%' }"
      :close-on-click-overlay="false"
    >
      <div class="confirm-window">
        <div class="title">
          操作菜单

          <div class="close-area" @click="hideWindow">
            <img
              class="close-button"
              src="https://api.mengjingloulan.com/storage/v3/close-bold.png"
            />
          </div>
        </div>
        <div class="content">
          <div class="button-list">
            <div class="btn" @click="contact(record.mobile)">
              拨打电话：{{ record.real_name }} {{ record.mobile }}
            </div>
            <div class="btn" @click="confirmSendOver(record)">确认送达</div>
          </div>
        </div>
      </div>
    </nut-popup>
  </div>
</template>

<script>
export default {
  name: "courier-notice",
  data() {
    return {
      orderList: [],
      show: false,
      record: {},
      recordIndex: 0,
    };
  },
  mounted() {
    this.scrollHeight = window.innerHeight;
    document.title = "统一配送订单";
    this.getOrderList();
  },
  methods: {
    getOrderList() {
      this.$api.community.courier
        .getDistributionOrderList()
        .then((response) => {
          Object.assign(this, {
            orderList: response.data,
          });
        });
    },
    clickTitle(record) {
      this.record = record;
      this.show = true;
    },
    hideWindow() {
      this.show = false;
    },
    contact(mobile) {
      this.$platform.wxsdk.wxRoute({
        type: "navigateTo",
        url: "/web/makePhoneCall/common?mobile=" + mobile,
      });
    },
    confirmSendOver(record) {
      let params = {
        ids: record.id,
      };
      this.$api.community.courier
        .confirmDistributionOrder(params)
        .then(() => {
          this.$dialog({
            id: "my-dialog",
            noOkBtn: true,
            content: "订单已确认送达",
            cancelBtnTxt: "我知道了",
          });
          this.orderList.splice(this.recordIndex, 1);
          this.show = false;
        })
        .catch((error) => {
          this.$dialog({
            id: "my-dialog",
            noOkBtn: true,
            content: error.message,
            cancelBtnTxt: "我知道了",
          });
        });
    },
  },
};
</script>

<style scoped lang="scss">
.notice {
  width: 100vw;
  height: 100vh;
  background: #f6f6f6;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .notice-title {
    background: #fff;
    padding: 10px;
    margin-bottom: 10px;
    font-size: 16px;
    position: relative;

    img {
      position: absolute;
      right: 5px;
      top: 5px;
      width: 25px;
      height: 25px;
    }
  }

  .notice-body {
    flex-grow: 1;
    box-sizing: border-box;
    padding: 10px 10px 0 10px;
    overflow-y: scroll;
    height: calc(100% - 60px);
    overflow-y: scroll;

    .goods-card {
      flex: 1;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      width: 9.2rem;
      margin: 0 auto;
      margin-bottom: 0.4rem;

      .card-title {
        flex: 1;
        display: flex;
        flex-direction: column;
        background: linear-gradient(129deg, #fbf2e2 0%, #f1e2c9 100%);
        border-radius: 0.26667rem 0.26667rem 0 0;
        padding: 8px 10px;
        color: #6c4806;

        .address-text {
          font-size: 14px;
          margin-bottom: 8px;
        }

        .user-and-mobile {
          display: flex;
          align-items: baseline;

          .real_name {
            font-size: 18px;
            margin-right: 10px;
          }

          .mobile {
            font-size: 14px;
          }
        }
      }

      .goods-list {
        background: #fff;
        border-radius: 0 0 0.26667rem 0.26667rem;
        font-size: 0.37333rem;
        display: flex;
        flex-direction: row;
        padding: 0.26667rem;

        .goods-cover {
          width: 1.33333rem;
          height: 1.33333rem;
          -webkit-border-radius: 0.13333rem;
          -moz-border-radius: 0.13333rem;
          border-radius: 0.13333rem;
          overflow: hidden;
          margin-right: 0.26667rem;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .goods-info {
          flex: 1;

          .goods-title {
            font-size: 14px;
          }

          .specs-text-and-number {
            display: flex;
            flex-direction: row;
            margin-top: 8px;

            .spec-text {
              flex: 1;
              display: flex;
              justify-content: flex-start;
            }

            .goods-number {
              flex: 1;
              display: flex;
              justify-content: flex-end;
            }
          }
        }
      }
    }
  }
}

.confirm-window {
  display: flex;
  flex-direction: column;

  .title {
    flex: 1;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgba(0, 0, 0, 0.6);
    padding: 15px 0;
    position: relative;

    .close-area {
      width: 50px;
      height: 50px;
      position: absolute;
      right: 10px;
      top: 0;
      display: flex;
      align-items: center;
      justify-content: center;

      .close-button {
        width: 15px;
        height: 15px;
      }
    }
  }

  .content {
    font-size: 16px;
    color: #333;
    padding: 10px 20px;
    line-height: 25px;
    display: flex;
    flex-direction: column;

    .button-list {
      .btn {
        flex: 1;
        height: 40px;
        bottom: calc(30px + env(safe-area-inset-bottom));
        bottom: calc(30px + constant(safe-area-inset-bottom));
        display: flex;
        align-items: center;
        justify-content: center;
        background: linear-gradient(139deg, #fac484 0%, #f46a17 100%);
        background-clip: padding-box;
        border: 4px solid rgba(244, 106, 23, 0.15);
        border-radius: 29px;
        font-size: 16px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        margin-top: 20px;
      }
    }
  }
}
</style>
